import React from 'react';

function Footer() {
    return (
        <footer className="fixed inset-x-0 bottom-0 text-deepIndigo font-Merriweather z-30 w-full bg-transparent">
            <div className="container mx-auto text-sm sm:text-xs text-center flex items-center justify-center px-4 py-2 sm:py-0 sm:h-[35%] bg-transparent">
                <p>&copy; {new Date().getFullYear()} Realalfa Financial Inc. A Real Estate Consulting Company. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
