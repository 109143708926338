import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules'; // Import modules properly

// Import Swiper styles
import 'swiper/css'; // Core Swiper CSS
import 'swiper/css/pagination'; // Pagination module CSS
// Note: Autoplay module doesn't have a separate CSS, but its functionality is in the JS

// Import service images
import ProjectAnalysisImage from '../assets/services/ProjectAnalysis.png';
import ProjectStructuringImage from '../assets/services/ProjectStructuring.png';
import TreasuryBundleImage from '../assets/services/TreasuryBundle.png';
import WorkOutPlansImage from '../assets/services/WorkOutPlans.png';

const Services = () => {
  // Array of service details
  const servicesData = [
    {
      heading: 'Project Analysis',
      body: 'We adopt novel techniques to due diligence that uniquely identify emerging trends, market conditions, and potential project risks and opportunities.',
      image: ProjectAnalysisImage,
    },
    {
      heading: 'Project Structuring',
      body: 'We provide bespoke structures for real estate financings that align interests and optimize stakeholder value.',
      image: ProjectStructuringImage,
    },
    {
      heading: 'Treasury Bundle',
      body: 'We offer developers a streamlined approach to managing liquidity, debt, solvency, and capital – all from one easy-to-use platform.',
      image: TreasuryBundleImage,
    },
    {
      heading: 'Work Out Plans',
      body: 'We strategically re-evaluate and analyze underperforming real estate investments to develop workout plans that bring them back on track to success.',
      image: WorkOutPlansImage,
    },
  ];

  return (
    <section id="services" className="min-h-screen flex relative overflow-hidden">
      <Swiper
        modules={[Pagination, Autoplay]} // Pass the required modules
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 5000 }}
        pagination={{ clickable: true }}
        loop={true}
        className="w-full h-full"
      >
        {servicesData.map((service, index) => (
          <SwiperSlide key={index}>
            <div className="flex flex-col md:flex-row min-h-screen">
              {/* Left Section with text */}
              <div className="md:w-1/2 w-full h-auto flex flex-col justify-center bg-lavender p-6 md:p-20">
                <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold font-ubuntu text-electricViolet mb-4 text-left">
                  {service.heading}
                </h1>
                <p
                  className="text-lg md:text-2xl lg:text-3xl font-light font-merriweather sans text-deepIndigo text-left"
                  style={{ lineHeight: '1.5' }}
                >
                  {service.body}
                </p>
              </div>

              {/* Right Section with the image */}
              <div className="md:w-1/2 w-full h-auto flex justify-center items-center p-6">
                <img
                  src={service.image}
                  alt={`${service.heading} Visual`}
                  className="max-h-[90%] max-w-[90%] object-contain"
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Services;
