import React, { useState } from 'react';
import AppLogo from '../assets/Favicon.png';

const Header = ({ activeSection, scrollToSection }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="flex justify-between items-center p-4 fixed top-0 w-full bg-lavender z-10">
      {/* Logo Section */}
      <div className="logo ml-4 text-xl font-bold">
        <img src={AppLogo} alt="App Logo" className="h-12 w-14" />
      </div>

      {/* Hamburger Icon for Mobile */}
      <div className="md:hidden mr-4">
        <button
          className="text-deepIndigo focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
            ></path>
          </svg>
        </button>
      </div>

      {/* Navigation Links */}
      <nav
        className={`${
          isMenuOpen ? 'flex' : 'hidden'
        } md:flex w-full font-light flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8 text-lg text-deepIndigo font-Merriweather`}
      >
        {['home', 'treasuCRE', 'services', 'about'].map((section) => (
          <button
            key={section}
            onClick={() => {
              scrollToSection(section);
              setIsMenuOpen(false);
            }}
            className={`capitalize relative hover:text-customGrey ${
              activeSection === section
                ? 'after:content-[""] after:absolute after:left-0 after:right-0 after:bottom-[-8px] after:h-[3px] after:bg-deepIndigo after:rounded-md'
                : ''
            }`}
          >
            {section}
          </button>
        ))}
      </nav>

      {/* Connect Button */}
      <div className="hidden md:block mr-4">
        <button
          className="bg-gradient-to-r from-blueGradient through-purpleGradient to-otherpurpleGradient text-lavender px-6 py-2 rounded-full shadow-lg hover:from-indigo-600 hover:to-purple-400 transition duration-300"
          onClick={() => (window.location.href = 'mailto:connect@realalfa.ca')} // Or redirect to the connect page
        >
          Connect
        </button>
      </div>
    </header>
  );
};

export default Header;
