import React, { useState, useEffect } from 'react';
import Header from './maincomponents/Header';
import Home from './maincomponents/Home';
import About from './maincomponents/About';
import Services from './maincomponents/Services';
import TreasuCRE from './maincomponents/TreasuCRE';
import Footer from './maincomponents/Footer';
import './App.css'; // Assuming you have Tailwind set up
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const App = () => {
  const [activeSection, setActiveSection] = useState('home');

  const handleScroll = () => {
    const sections = ['home', 'about', 'services', 'treasuCRE'];
    const scrollPos = window.scrollY + window.innerHeight / 2;

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element.offsetTop <= scrollPos && element.offsetTop + element.offsetHeight > scrollPos) {
        setActiveSection(section);
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
  };

  return (
    <div className="font-sans bg-lavender min-h-screen text-center">
      <Header activeSection={activeSection} scrollToSection={scrollToSection} />
      
      {/* Sections */}
      <Home />
      <TreasuCRE />
      <Services />
      <About />
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default App;
