import React from 'react';

const TreasuCRE = () => {
  return (
    <section 
      id="treasuCRE" 
      className="h-screen flex flex-col justify-center items-center bg-lavender"
    >
      <div 
        className="w-full bg-lavender"
        style={{ 
          height: '90%', 
          backgroundColor: '#E6E6FA',
          maxWidth: '1200px' // Optional for large screen limitation
        }}
      >
        <iframe 
          src="https://www.youtube.com/embed/jL-9gKfPgMo"
          className="w-full h-full" 
          frameBorder="0" 
          scrolling="no" 
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  
          title="TreasuCRE"
        ></iframe>
      </div>

      <style jsx>{`
        section {
          padding: 20px;
        }

        @media (max-width: 768px) {
          section {
            height: auto;
          }
          div {
            height: 300px; // Reduced height for smaller screens
          }
          iframe {
            height: 100%;
          }
        }
      `}</style>
    </section>
  );
};

export default TreasuCRE;
