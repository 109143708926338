import React from 'react';
import { ReactTyped } from 'react-typed';
import homepage from '../assets/Home.png';

const Home = () => {
  return (
    <section id="home" className="h-screen flex flex-col md:flex-row relative overflow-hidden">
      {/* Left Section with text */}
      <div className="w-full md:w-1/2 flex justify-center items-center bg-lavender relative z-0 px-4 md:px-8 py-8 md:py-0">
        <h1 className="text-4xl md:text-5xl lg:text-7xl 2xl:text-8xl font-bold font-ubuntu text-deepIndigo text-center" style={{ lineHeight: '1.25' }}>
          <ReactTyped
            strings={[
              'Unlock <span class="text-electricViolet">Value</span> Through<span class="text-electricViolet"> Innovative</span> Insights!',
            ]}
            typeSpeed={120}
            backSpeed={30}
            backDelay={2000}
            loop
          />
        </h1>
      </div>

      {/* Right Section with the homepage image */}
      <div className="w-full md:w-1/2 flex justify-center items-center relative z-10">
        <img 
          src={homepage} 
          alt="Homepage Visual" 
          className="h-64 md:h-full w-auto object-contain"
        />
      </div>
    </section>
  );
};

export default Home;
