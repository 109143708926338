import React, { useState, useEffect } from 'react';
import ValueAnswerImage from '../assets/about/ValueAnswer.png';
import ValueQuestionImage from '../assets/about/ValueQuestion.png';

const About = () => {
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    const flipInterval = setInterval(() => {
      setFlipped((prevFlipped) => !prevFlipped);
    }, 5000); // Flip every 5 seconds

    return () => clearInterval(flipInterval); // Cleanup the interval on unmount
  }, []);

  return (
    <section id="about" className="relative h-screen flex justify-center items-center bg-lavender overflow-hidden">
      <div className="flip-container w-full h-full">
        <div className={`flipper ${flipped ? 'flipped' : ''}`}>
          {/* Front Image */}
          <div
            className="front h-full flex flex-col justify-center bg-cover bg-right sm:bg-center"
            style={{ backgroundImage: `url(${ValueQuestionImage})` }}
          >
            <div className="w-5/6 sm:w-4/5 md:w-2/3 lg:w-1/2 p-5 sm:p-10 ml-auto">
              <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-regular font-ubuntu text-deepIndigo text-right" style={{ lineHeight: '1.75' }}>
                Through our out-of-the-box strategies, we pursue the enhancement of the overall value and benefits derived from real estate transactions by adopting approaches that expand the potential benefits for all stakeholders involved.
              </p>
            </div>
          </div>

          {/* Back Image */}
          <div
            className="back h-full flex flex-col justify-center bg-cover bg-right sm:bg-center"
            style={{ backgroundImage: `url(${ValueAnswerImage})` }}
          >
            <div className="w-5/6 sm:w-4/5 md:w-2/3 lg:w-1/2 p-5 sm:p-10 ml-auto">
              <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-regular font-ubuntu text-deepIndigo text-right" style={{ lineHeight: '1.75' }}>
                We are all about creating scenarios where each stakeholder can experience improved outcomes, in an environment where collective success is prioritized.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
